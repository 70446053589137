import Vue from 'vue'
import App from '../components/home-filters/App.vue'

document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        render: h => h(App, {
            props: {
                filters: filters,
                config: listConfig,
                locale: locale,
                configTexts: configTexts,
                rewardsTitle: rewardsTitle,
                filterText: filterText,
                discountBtn: discountBtn,
                filtersTerminals: filtersTerminals,
                hasPromotions: hasPromotions
            }
        })
    }).$mount('#home-filters')
})