
<div>
    <div class="d-flex flex-wrap justify-content-between align-items-center border-bottom pb-3 mb-4">
        <h2 class="h2-subtitle border-bottom-0 pb-0 mb-0 me-4" style="text-transform: none;" v-html="rewardsTitle"></h2>
        <div class="d-flex flex-wrap justify-content-between align-items-center">
            <label class="filter-label me-2">{{filterText}}</label>
            <div class="d-flex flexm-sm-nowrap">
                <select class="input-type-2 filter-selector bg-transparent" v-model="taxonomy">
                    <option v-for="(filter, index) in filters" :key="index" :value="filter.slug ? filter.slug : null">{{filter.title}}</option>
                </select>

                <select class="input-type-2 filter-selector bg-transparent" v-model="terminal">
                    <option v-for="(filter, index) in filtersTerminals" :key="index" :value="filter.slug ? filter.slug : null">{{filter.title}}</option>
                </select>
            </div>
        </div>
    </div>
    <template v-if="!status.loading && component">
        <div class="d-flex flex-column justify-content-between">
            <div class="recompensas-card__container">
                <div class="class-improv d-flex justify-content-start" v-if="items && items.length > 0">
                    <template v-for="(item, index) in items">
                        <component :is="component" :item="item" :configTexts="configTexts" :locale="locale" :contentVersionID="contentVersionID" :key="index"/>
                    </template>
                </div>
                <div v-else>
                    {{$locale == 'es' ? 'No se encontraron resultados para la búsqueda realizada' : 'No results were found for the search'}}
                </div>
                

                <div class="d-flex align-items-center justify-content-end mt-2 pt-2 w-100">
                    <a class="btn px-4 btn-secondary d-flex flex-nowrap align-items-center" :href="promotionsUrl">
                        <span class="pe-3" v-html="discountBtn"></span>
                        <img src="https://asurcloudrunprod-16905.kxcdn.com/public-assets/right-arrow-icon" class='arrow-icon' alt='arrow'/>
                    </a>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <Skeleton />
    </template>
</div>
